import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { ReactComponent as NitroLogo } from '~resources/nitro-icon.svg';
import './footer.scss';

export interface FooterProps {
}

function Footer() {
    const { t } = useTranslation();
    const { privacyPolicyUrl } = useSettings();

    return (
        <div className={'c-footer'}>
            <div className={'c-footer__copy'}>
                <Typography token={TypographyToken.DesktopCreditXs}>
                    {t('powered-by')}
                </Typography>
                <NitroLogo className={'c-footer__copy-logo'}/>
                <Typography token={TypographyToken.DesktopCreditXs}>
                    {t('all-rights-reserved', { year: (new Date()).getFullYear() })}
                </Typography>
            </div>
            <div className={'c-footer__privacy'}>
                <Typography<'a'>
                    token={TypographyToken.DesktopCreditXs}
                    tagName={'a'}
                    target={'_blank'}
                    href={privacyPolicyUrl}
                    className={'c-footer__privacy-link'}
                >
                    {t('privacy-policy')}
                </Typography>
            </div>
        </div>
    );
}

export default Footer;