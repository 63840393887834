import { NdsButton } from 'rcl';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { BaseApi } from '~api/base.api';
import { GetSubscriptionModel } from '~api/base.models';
import Error from '~components/error';
import Loader from '~components/loader';
import Typography, { TypographyToken } from '~components/typography';
import { useApi } from '~contexts/api';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { useAbortEffect } from '~hooks/effects';
import { SubscriptionStatus } from '~models';
import FormDetails from './form-details';
import FormFields from './form-fields';
import './form.view.scss';

export interface FormViewProps {
}

function FormView() {
    const { t } = useTranslation();
    const api = useApi(BaseApi);
    const { languages } = useSettings();
    const { language } = useLanguages();
    const navigate= useNavigate();
    const [loading, setLoading] = useState(true);
    const [genericError, setGenericError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();
    const id = useMemo(() => searchParams.get('id'), [searchParams]);

    const [subscription, setSubscription] = useState<GetSubscriptionModel.Result>();

    const fetchSubscription = useCallback(async (signal?: AbortSignal) => {
        if (!id) {
            return;
        }
        setLoading(true);
        const response = await api.getSubscriptionInfo({ id }, signal);

        setSubscription(response);
        setLoading(false);
    }, [api, id]);

    useAbortEffect((signal) => {
        fetchSubscription(signal);
    }, [fetchSubscription]);

    const [values, setValues] = useState({
        companyName: '',
        urlPrefix: '',
        country: '',
        language: languages.find(lang => lang.toUpperCase() === language.toUpperCase()) || '',
        marketing: false,
        consent: false,
    });
    const [errors, setErrors] = useState<string[]>([]);

    const onChange = useCallback((name: string, value: string | boolean) => {
        setValues(current => {
            return {
                ...current,
                [name]: value,
            };
        });
    }, []);

    const onSubmit = useCallback(async () => {
        if (!id) {
            return;
        }

        setLoading(true);
        try {
            const response = await api.submitSubscriptionInfo({ id }, values);

            if (response.success) {
                navigate('/confirmation', { replace: true });
            } else {
                setErrors(response.errors.map(({ code }) => code));
            }
            setGenericError(false);
        } catch (e) {
            setGenericError(true);
        }
        setLoading(false);
    }, [api, id, navigate, values]);

    return (
        <form className={'v-form'} onSubmit={onSubmit}>
            {loading && (
                <div className={'v-form__loader'}>
                    <Loader center size={'lg'} />
                </div>
            )}
            {subscription && (
                <>
                    <div className={'v-form__row'}>
                        <Typography token={TypographyToken.DesktopHeaderLg} tagName={'h1'} className={'mb--2'}>
                            {t('main-title')}
                        </Typography>
                        <Typography token={TypographyToken.DesktopDescriptionMd} tagName={'p'}>
                            {t('main-description')}
                        </Typography>
                    </div>
                    <div className={'v-form__row'}>
                        <FormDetails
                            currentUser={subscription.displayName}
                            email={subscription.emailAddress}
                            subscriptionName={subscription.subscriptionName}
                            planName={subscription.planName}
                            subscriptionState={subscription.status}
                        />
                    </div>
                    {id && subscription.status === SubscriptionStatus.NotStarted && (
                        <>
                            <div className={'v-form__row'}>
                                <Typography token={TypographyToken.DesktopDescriptionBoldXl} tagName={'h2'} className={'mb--4'}>
                                    {t('available-customer-actions')}
                                </Typography>
                                <FormFields
                                    onChange={onChange}
                                    onSubmit={onSubmit}
                                    values={values}
                                    errors={errors}
                                />
                            </div>
                            {genericError && (
                                <div className={'v-form__row'}>
                                    <Error label={t('something-went-wrong')}/>
                                </div>
                            )}
                            <div className={'v-form__row'}>
                                <NdsButton
                                    className={'v-form__submit'}
                                    label={t('btn-complete-setup')}
                                    displayBlock
                                    onClick={onSubmit}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </form>
    );
}

export default FormView;