import { DependencyList, useEffect } from 'react';

export type AbortEffectCallback = (signal: AbortSignal) => (void | (() => void));

/** Same as `useEffect`, but provides an `AbortSignal` that gets aborted when the effect is refreshed */
export function useAbortEffect(effect: AbortEffectCallback, deps?: DependencyList | undefined) {
    useEffect(() => {
        const aborter = new AbortController();
        const destructor = effect(aborter.signal);

        return () => {
            destructor?.();
            aborter.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}


// async effect requires little hack to prevent warnings while deps are properly validated (by eslint)
// as async function cannot be passed directly to effect there is another normal function required on top
// using this is still simple, instead of:
// useAsyncEffect(async (signal) => {...}, [deps]);
// make the function that returns async one
// useAsyncEffect(() => async (signal) => {...}, [deps]);
export type AsyncEffectCallback = (signal: AbortSignal) => Promise<void>;

export const useAsyncEffect = (effect: () => AsyncEffectCallback, deps?: DependencyList | undefined) => {
    useEffect(() => {
        const aborter = new AbortController();

        effect()(aborter.signal);

        return () => {
            aborter.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};