import { Checkbox, NdsGroup, NdsInput, NdsSelect, NdsSelectOption } from 'rcl';
import { StatesEnum } from 'rcl/lib/_types/designsystem.enums';
import { KeyboardEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useLanguages, useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import { StringUtils } from '~lib';
import { pascalCaseToKebabCase } from '~lib/string.utils';
import { LanguageDataLabel } from '~models';
import './form-fields.scss';
import { useCountriesList, useLanguagesList } from './hooks';

export interface FormFieldsProps {
    onChange: (name: string, value: string | boolean) => void;
    onSubmit: () => void;
    values: Record<string, string | boolean>;
    errors: string[];
}

const COMPANY_NAME_MAX_LENGTH = 255;
const URL_PREFIX_MAX_LENGTH = 40;

const FormFields = ({
    onChange,
    onSubmit,
    values,
    errors,
}: FormFieldsProps) => {
    const { t } = useTranslation();
    const { urlDomain } = useSettings();
    const { language } = useLanguages();
    const [refreshState, setRefreshState] = useState(false);
    const languages = useLanguagesList();
    const countries = useCountriesList();

    useEffect(() => {
        setRefreshState(true);
        const id = setTimeout(() => {
            setRefreshState(false);
        });

        return () => {
            clearTimeout(id);
            setRefreshState(false);
        };
    }, [language]);

    const onEnterClick = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }, [onSubmit]);

    const parsedErrors = useMemo(() => {
        return errors.reduce((acc: Record<string, string>, current: string) => {
            let [error, fieldName] = current.split('.');

            fieldName = StringUtils.unCapitalizeFirstLetter(fieldName);
            error = pascalCaseToKebabCase(error);

            acc[fieldName] = error;

            return acc;
        }, {});
    }, [errors]);

    const getErrorLabel = useCallback((error?: string, max?: number): string | undefined => {
        return !error
            ? undefined
            : t(`error-url-prefix-${error}` as LanguageDataLabel<'base'>, { max });
    }, [t]);

    return (
        <div className={'form-fields'}>
            <div className={'form-fields__field'}>
                <NdsInput
                    required
                    state={parsedErrors['companyName'] ? StatesEnum.ERROR : StatesEnum.DEFAULT}
                    label={t('form-company-name')}
                    value={values['companyName'] as string}
                    onNdsChange={(event) => onChange('companyName', event.detail.value)}
                    onKeyDown={onEnterClick}
                    hintText={getErrorLabel(parsedErrors['companyName'], COMPANY_NAME_MAX_LENGTH)}
                />
            </div>
            <div className={'form-fields__field form-fields__field--url-prefix'}>
                <NdsInput
                    required
                    className={'form-fields__url-prefix'}
                    state={parsedErrors['urlPrefix'] ? StatesEnum.ERROR : StatesEnum.DEFAULT}
                    label={t('form-url-prefix')}
                    value={values['urlPrefix'] as string}
                    onNdsChange={(event) => onChange('urlPrefix', event.detail.value)}
                    onKeyDown={onEnterClick}
                    hintText={getErrorLabel(parsedErrors['urlPrefix'], URL_PREFIX_MAX_LENGTH)}
                />
                <Typography token={TypographyToken.UiFormsInputMd} tagName={'div'} className={'form-fields__url-suffix'}>
                    {urlDomain}
                </Typography>
            </div>
            <div className={'form-fields__field'}>
                {!refreshState && (
                    <NdsSelect
                        required
                        state={parsedErrors['country'] ? StatesEnum.ERROR : StatesEnum.DEFAULT}
                        label={t('form-country')}
                        value={values['country'] as string}
                        onNdsChange={(event) => onChange('country', event.detail.value[0])}
                        hintText={getErrorLabel(parsedErrors['country'])}
                    >
                        <NdsGroup>
                            {countries.map(({ name, code }) => (
                                <NdsSelectOption key={code} value={code} label={name}/>
                            ))}
                        </NdsGroup>
                    </NdsSelect>
                )}
            </div>
            <div className={'form-fields__field'}>
                {!refreshState && (
                    <NdsSelect
                        required
                        state={parsedErrors['language'] ? StatesEnum.ERROR : StatesEnum.DEFAULT}
                        label={t('form-environment-language')}
                        value={values['language'] as string}
                        onNdsChange={(event) => onChange('language', event.detail.value[0])}
                        hintText={getErrorLabel(parsedErrors['language'])}
                    >
                        <NdsGroup>
                            {languages.map(({ name, code }) => (
                                <NdsSelectOption key={code} value={code} label={name}/>
                            ))}
                        </NdsGroup>
                    </NdsSelect>
                )}
            </div>
            <div className={'form-fields__field form-fields__field--full'}>
                <Checkbox
                    label={t('form-subscribe-to-marketing')}
                    description={t('form-subscribe-to-marketing-description')}
                    name={'marketing'}
                    value={values['marketing'] as boolean}
                    onChange={(_, value) => onChange('marketing', value)}
                />
            </div>
            <div className={'form-fields__field form-fields__field--full'}>
                <Checkbox
                    required
                    error={!!parsedErrors['receiveSystemUpdates']}
                    label={t('form-receive-system-updates')}
                    description={t('form-receive-system-updates-description')}
                    name={'consent'}
                    value={values['consent'] as boolean}
                    onChange={(_, value) => onChange('consent', value)}
                />
            </div>
        </div>
    );
};

export default FormFields;