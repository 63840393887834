import { Suspense } from 'react';
import Loader from '~components/loader';
import { AppContainer } from '~containers/app';

function App() {
    const location = window.location;

    if (location.search && !location.hash) {
        location.replace(location.origin + location.pathname + '#' + location.search);
    }

    return (
        <Suspense fallback={<Loader/>}>
            <AppContainer/>
        </Suspense>
    );
}

export default App;
