import { i18n, init, InitOptions, use } from 'i18next';
import i18nextBrowserLanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import i18nextHttpBackend, { HttpBackendOptions } from 'i18next-http-backend';
import { FORCE_LOCAL_LOCALES, IS_DEVELOPMENT_ENV } from '~constants';
import { Language } from '~models';

export async function configureI18next(
    i18next: i18n, availableLanguages: Language[], fallbackLanguage: Language,
) {
    use(i18nextBrowserLanguageDetector);
    use(i18nextHttpBackend);

    const options: InitOptions & DetectorOptions = {
        load: 'languageOnly', // we only care about language, not region (i.e. `en-US` becomes `en`)
        fallbackLng: fallbackLanguage,
        supportedLngs: availableLanguages,
        ns: ['base'],
        defaultNS: 'base',
        keySeparator: 'NO-KEY-SEPARATOR', // We don't allow key separators
        returnEmptyString: true, // Consider empty strings as missing values
        // debug: true,
        interpolation: { escapeValue: false }, // not needed for react as it escapes by default
        // i18nextBrowserLanguageDetector options
        detection: {
            lookupCookie: 'i18nLang',
            cookieMinutes: 60,
            order: ['cookie', 'querystring', 'navigator', 'htmlTag'],
            convertDetectedLanguage: (lng)  => {
                return lng?.split('-')[0];
            },
        },
    };

    // Either load the locales files directly from the file system, or set their location in the BE
    // Note that these must perfectly correspond to the webpack settings
    if (IS_DEVELOPMENT_ENV || FORCE_LOCAL_LOCALES) {
        options.backend = { loadPath: 'i18n/{{ns}}/{{lng}}.json' };
    } else {
        options.backend = { loadPath: '/locales/{{ns}}/{{lng}}.json' };
    }

    await init<HttpBackendOptions>(options);

    return i18next;
}