export enum TypographyToken {
// @postinstallStart - don't remove this line nor modify following content
    DesktopDisplayXxl = 'desktop-display-xxl',
    DesktopDisplayXl = 'desktop-display-xl',
    DesktopDisplayLg = 'desktop-display-lg',
    DesktopDisplayMd = 'desktop-display-md',
    DesktopDisplaySm = 'desktop-display-sm',
    DesktopDisplayXs = 'desktop-display-xs',
    DesktopHeaderXl = 'desktop-header-xl',
    DesktopHeaderLg = 'desktop-header-lg',
    DesktopHeaderMd = 'desktop-header-md',
    DesktopHeaderSm = 'desktop-header-sm',
    DesktopHeaderXs = 'desktop-header-xs',
    DesktopDescriptionXl = 'desktop-description-xl',
    DesktopDescriptionLg = 'desktop-description-lg',
    DesktopDescriptionMd = 'desktop-description-md',
    DesktopDescriptionSm = 'desktop-description-sm',
    DesktopDescriptionBoldXl = 'desktop-description-bold-xl',
    DesktopDescriptionBoldLg = 'desktop-description-bold-lg',
    DesktopDescriptionBoldMd = 'desktop-description-bold-md',
    DesktopDescriptionBoldSm = 'desktop-description-bold-sm',
    DesktopTagMd = 'desktop-tag-md',
    DesktopTagSm = 'desktop-tag-sm',
    DesktopTagXs = 'desktop-tag-xs',
    DesktopQuoteXl = 'desktop-quote-xl',
    DesktopQuoteLg = 'desktop-quote-lg',
    DesktopCaptionSm = 'desktop-caption-sm',
    DesktopBylineSm = 'desktop-byline-sm',
    DesktopBylineXs = 'desktop-byline-xs',
    DesktopCreditXs = 'desktop-credit-xs',
    MobileDisplayXxl = 'mobile-display-xxl',
    MobileDisplayXl = 'mobile-display-xl',
    MobileDisplayLg = 'mobile-display-lg',
    MobileDisplayMd = 'mobile-display-md',
    MobileDisplaySm = 'mobile-display-sm',
    MobileDisplayXs = 'mobile-display-xs',
    MobileHeaderXl = 'mobile-header-xl',
    MobileHeaderLg = 'mobile-header-lg',
    MobileHeaderMd = 'mobile-header-md',
    MobileHeaderSm = 'mobile-header-sm',
    MobileHeaderXs = 'mobile-header-xs',
    MobileDescriptionXl = 'mobile-description-xl',
    MobileDescriptionLg = 'mobile-description-lg',
    MobileDescriptionMd = 'mobile-description-md',
    MobileDescriptionSm = 'mobile-description-sm',
    MobileDescriptionBoldXl = 'mobile-description-bold-xl',
    MobileDescriptionBoldLg = 'mobile-description-bold-lg',
    MobileDescriptionBoldMd = 'mobile-description-bold-md',
    MobileDescriptionBoldSm = 'mobile-description-bold-sm',
    MobileTagMd = 'mobile-tag-md',
    MobileTagSm = 'mobile-tag-sm',
    MobileTagXs = 'mobile-tag-xs',
    MobileQuoteXl = 'mobile-quote-xl',
    MobileQuoteLg = 'mobile-quote-lg',
    MobileCaptionSm = 'mobile-caption-sm',
    MobileBylineSm = 'mobile-byline-sm',
    MobileBylineXs = 'mobile-byline-xs',
    MobileCreditXs = 'mobile-credit-xs',
    IconLightXxl = 'icon-light-xxl',
    IconLightXl = 'icon-light-xl',
    IconLightLg = 'icon-light-lg',
    IconLightMd = 'icon-light-md',
    IconLightSm = 'icon-light-sm',
    IconLightXs = 'icon-light-xs',
    IconHeavyXxl = 'icon-heavy-xxl',
    IconHeavyXl = 'icon-heavy-xl',
    IconHeavyLg = 'icon-heavy-lg',
    IconHeavyMd = 'icon-heavy-md',
    IconHeavySm = 'icon-heavy-sm',
    IconHeavyXs = 'icon-heavy-xs',
    IconBrandsXxl = 'icon-brands-xxl',
    IconBrandsXl = 'icon-brands-xl',
    IconBrandsLg = 'icon-brands-lg',
    IconBrandsMd = 'icon-brands-md',
    IconBrandsSm = 'icon-brands-sm',
    IconBrandsXs = 'icon-brands-xs',
    UiButtonsXl = 'ui-buttons-xl',
    UiButtonsLg = 'ui-buttons-lg',
    UiButtonsMd = 'ui-buttons-md',
    UiButtonsSm = 'ui-buttons-sm',
    UiButtonsXs = 'ui-buttons-xs',
    UiFormsLabelLg = 'ui-forms-label-lg',
    UiFormsLabelMd = 'ui-forms-label-md',
    UiFormsLabelSm = 'ui-forms-label-sm',
    UiFormsLabelXs = 'ui-forms-label-xs',
    UiFormsInputLg = 'ui-forms-input-lg',
    UiFormsInputMd = 'ui-forms-input-md',
    UiFormsInputSm = 'ui-forms-input-sm',
    UiFormsInputXs = 'ui-forms-input-xs',
    UiMenuLg = 'ui-menu-lg',
    UiMenuMd = 'ui-menu-md',
    UiMenuSm = 'ui-menu-sm',
    UiMenuXs = 'ui-menu-xs',
// @postinstallEnd - don't remove this line
}