import { AbortedError } from '~models';

export namespace PromiseUtils {
    /**
     * Create a promise that resolves after `ms` milliseconds. Is a wrapper around `setTimeout` for ease of use.
     *
     * @param ms Amount of milliseconds to wait
     * @param signal abort signal
     * @returns A promise
     */
    export function wait(ms?: number, signal?: AbortSignal) {

        return new Promise<void>((resolve, reject) => {
            const controller = new AbortController();

            // Resolve this promise after ms
            const handle = setTimeout(() => {
                resolve();
                controller.abort(); // Make sure to unsubscribe to the signal to avoid memory leaks
            }, ms);

            // Reject when aborted
            signal?.addEventListener(
                'abort', () => {
                    reject(new AbortedError(signal));
                    clearTimeout(handle);
                }, { signal: controller.signal },
            );

        });

    }
    /**
     * Returns a promise that never resolves...
     */
    export function forever(signal?: AbortSignal) {
        return new Promise<never>((resolve, reject) => {
            signal?.addEventListener('abort', () => reject());
        });
    }
}