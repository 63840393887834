import classNames from 'classnames';
import { NdsButton, NdsDivider } from 'rcl';
import { BkgModesEnum, NdsButtonTypesEnum, OrientationsEnum } from 'rcl/lib/_types';
import { SizesEnums } from 'rcl/lib/_types/designsystem.enums';
import { HTMLAttributes } from 'react';
import { useTranslation } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';
import './header.scss';
import HeaderMenu from '../header-menu';
import Logo from '../logo';

export interface HeaderProps extends HTMLAttributes<HTMLElement> {
}

function Header({ className, ...props }: HeaderProps) {
    const { t } = useTranslation();
    const { helpUrl } = useSettings();

    return (
        <header {...props} className={classNames('c-header', className)}>
            <div className={'c-header__area c-header__area--left'}>
                <Logo />
            </div>
            <div className={'c-header__area c-header__area--right'}>
                <a
                    href={helpUrl}
                    target={'_blank'}
                    className={'c-header__support-link'}
                    rel='noreferrer'
                >
                    <NdsButton
                        leftIcon={'fa-solid-circle-question'}
                        label={t('need-help')}
                        bgMode={BkgModesEnum.DARK}
                        buttonType={NdsButtonTypesEnum.NEUTRAL}
                        size={SizesEnums.XSMALL}
                    />
                </a>
                <NdsDivider orientation={OrientationsEnum.VERTICAL} bgMode={BkgModesEnum.DARK}/>
                <HeaderMenu />
            </div>
        </header>
    );
}

export default Header;