import classNames from 'classnames';
import { HtmlHTMLAttributes } from 'react';
import './loader.scss';

export interface LoaderProps extends HtmlHTMLAttributes<HTMLDivElement> {
    size?: 'default' | 'sm' | 'lg';
    center?: boolean;
}

// TODO should evolve to whole page loader only as NdsProgress is there , actually it's simple copy paste from wysiwys
function Loader({ size = 'default', center = false, children, className, ...props }: LoaderProps) {
    return (
        <div
            {...props}
            className={classNames(
                `c-loader c-loader--${size}`, { 'c-loader--center': center }, className,
            )}
        >
            <div className='c-loader__container'>
                <div className='c-loader__dot c-loader__dot--1' />
                <div className='c-loader__dot c-loader__dot--2' />
                <div className='c-loader__dot c-loader__dot--3' />
                <div className='c-loader__dot c-loader__dot--4' />
            </div>
        </div>
    );
}

export default Loader;