import { HttpMethod } from './http.enums';
import { HttpRequest } from './http.request';

interface RequestProcessor {
    (request: HttpRequest): HttpRequest;
}

export class Http {
    /**
     * @param requestProcessor allows to modify any request from top level manager - ApiContext
     * @param baseUrl base url for all request of this Http class instance, can be overridden by passing absolute url as url param
     */
    constructor(private readonly requestProcessor: RequestProcessor = (r) => r,
        private readonly baseUrl?: string) {
    }

    public request(method: HttpMethod, url: string): HttpRequest {
        const request = new HttpRequest(
            method, url, this.baseUrl,
        );

        return this.requestProcessor(request);
    }

    public get(url: string): HttpRequest {
        return this.request(HttpMethod.Get, url);
    }

    public put(url: string): HttpRequest {
        return this.request(HttpMethod.Put, url);
    }

    public post(url: string): HttpRequest {
        return this.request(HttpMethod.Post, url);
    }

    public delete(url: string): HttpRequest {
        return this.request(HttpMethod.Delete, url);
    }
}

export default Http;