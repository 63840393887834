import { useMemo } from 'react';
import { useLanguages as useI18nLanguages } from '~contexts/i18n';
import { useSettings } from '~contexts/settings';

export const useLanguagesList = () => {
    const { language } = useI18nLanguages();
    const { languages } = useSettings();

    return useMemo(() => {
        const regionNames = new Intl.DisplayNames([language], { type: 'language' });

        return  languages.map((code: string) => {
            let translated;
            try {
                translated = regionNames.of(code.toLowerCase());
            } catch (e) {}

            return {
                code,
                name: translated ?? code,
            };
        }).sort((a, b) => a.name > b.name ? 1 : -1);
    }, [language, languages]);
};

export const useCountriesList = () => {
    const { countries: countriesDict } = useSettings();
    const { language } = useI18nLanguages();

    return useMemo(() => {
        const regionNames = new Intl.DisplayNames([language], { type: 'region' });

        return Object.entries(countriesDict).map(([code, fallback]) => {
            let translated;
            try {
                translated = regionNames.of(code);
            } catch (e) {}

            return {
                code,
                name: translated ?? fallback,
            };
        }).sort((a, b) => a.name > b.name ? 1 : -1);
    }, [countriesDict, language]);
};