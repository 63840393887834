import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { PropsWithClassNames } from '~models';
import './layout.scss';

export type LayoutProps = PropsWithChildren<PropsWithClassNames>;

function Layout ({ className, children }: LayoutProps) {
    return (
        <div className={classNames('c-layout', className)}>
            {children}
        </div>
    );
}

export type LayoutElementProps = PropsWithChildren<PropsWithClassNames>;

Layout.Header = ({ className, children }: LayoutElementProps) => (
    <div className={classNames('c-layout__header', className)}>{children}</div>
);
Layout.Content = ({ className, children }: LayoutElementProps) => (
    <div className={classNames('c-layout__content', className)}>{children}</div>
);
Layout.Footer = ({ className, children }: LayoutElementProps) => (
    <div className={classNames('c-layout__footer', className)}>{children}</div>
);
Layout.Sidebar = ({ className, children }: LayoutElementProps) => (
    <div className={classNames('c-layout__sidebar', className)}>{children}</div>
);

export default Layout;