import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { HTMLAttributesAndClassName } from '~models';
import './content.scss';

export interface ContentProps extends HTMLAttributesAndClassName<HTMLDivElement>, PropsWithChildren {
}

function Content({ className, children, ...props }: ContentProps) {
    return (
        <main
            {...props}
            className={classNames('c-content', className)}
        >
            {children}
        </main>
    );
}

export default Content;