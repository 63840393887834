import { i18n } from 'i18next';
import { createContext } from 'react';
import { SetLanguageFunction } from '~contexts/i18n/i18n.types';
import { Language, LanguageName } from '~models';

export type I18nContextType = {
    i18next: i18n;
    /** The primary language configured by the user */
    language: Language;
    /** All available languages */
    languages: Record<Language, LanguageName>;
    setLanguage: SetLanguageFunction;
}

export const I18nContext = createContext<I18nContextType>({} as I18nContextType);

export default I18nContext;