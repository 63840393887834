import classNames from 'classnames';
import { NdsIconFont } from 'rcl';
import { SizesEnums } from 'rcl/lib/_types/designsystem.enums';
import { HTMLAttributes, PropsWithChildren } from 'react';
import { PropsWithClassNames } from '~models';
import './error.scss';
import Typography, { TypographyToken } from '../typography';

export interface ErrorProps extends PropsWithClassNames<HTMLAttributes<HTMLDivElement>> {
    label?: string;
    // i18nKey?: string,
    icon?: string | false;
}

function Error({
    label,
    icon = 'fa-solid-exclamation-circle',
    className,
    children,
    ...props
}: PropsWithChildren<ErrorProps>) {
    // const {t} = useTranslation('error');
    return (
        <div className={classNames('c-error', className)} {...props}>
            {icon && <NdsIconFont fontName={icon} size={SizesEnums.MEDIUM} className={'c-error__icon'} />}
            <Typography
                tagName={'div'}
                token={TypographyToken.MobileBylineXs}
                mediumScreenToken={TypographyToken.DesktopBylineXs}
            >{label}{children}</Typography>
        </div>
    );
}

export default Error;