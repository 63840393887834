import { GetSettingsModel, GetSubscriptionModel, SubmitSubscriptionModel } from '~api/base.models';
import { IApi } from '~contexts/api';
import { PromiseUtils } from '~lib';
import Http from '~lib/http';
import { HttpStatus } from '~lib/http/http.enums';
import { HttpError } from '~lib/http/http.error';

export class BaseApi implements IApi {
    constructor(private http: Http) {
    }

    async getSettings(signal?: AbortSignal) {
        try {
            const response = await this.http
                .get('/api/settings')
                .execute(signal);

            return await response.jsonAs<GetSettingsModel.Result>();
        } catch (e) {
            if (signal?.aborted) {
                return PromiseUtils.forever();
            }
            console.warn(e);
            throw e;
        }
    }

    async getSubscriptionInfo({ id }: GetSubscriptionModel.Params, signal?: AbortSignal) {
        try {
            const response = await this.http
                .get(`/api/${id}/info`)
                .execute(signal);

            return await response.jsonAs<GetSubscriptionModel.Result>();
        } catch (e) {
            if (signal?.aborted) {
                return PromiseUtils.forever();
            }
            console.warn(e);
            throw e;
        }
    }

    async submitSubscriptionInfo(
        { id }: SubmitSubscriptionModel.Params,
        body: Record<string, string | boolean>,
        signal?: AbortSignal,
    ): Promise<SubmitSubscriptionModel.Result> {
        try {
            await this.http
                .post(`/api/${id}/submit`)
                .withJsonBody({
                    companyName: body.companyName,
                    urlPrefix: body.urlPrefix,
                    language: (body.language as string)?.toLowerCase(),
                    country: body.country,
                    receiveMarketingUpdates: body.marketing,
                    receiveSystemUpdates: body.consent,
                } as SubmitSubscriptionModel.Body)
                .execute(signal);

            return { success: true };

        } catch (e: unknown) {
            if (e instanceof HttpError && e.response.status === HttpStatus.BadRequest) {
                return {
                    success: false,
                    errors: e.body,
                };
            }

            if (signal?.aborted) {
                return PromiseUtils.forever();
            }
            console.warn(e);
            throw e;
        }
    }
}