import { ReactNode, useMemo, useState } from 'react';
import { BaseApi } from '~api/base.api';
import Loader from '~components/loader';
import { useApi } from '~contexts/api';
import { useAsyncEffect } from '~hooks/effects';
import { Language, LanguageName, Settings } from '~models';
import { SettingsContext, SettingsContextType } from './settings.context';

export interface SettingsContextProviderProps {
    children: ReactNode;
}

export function SettingsContextProvider({ children, ...props }: SettingsContextProviderProps) {
    const [settings, setSettings] = useState<Settings>();
    const baseApi = useApi(BaseApi);

    useAsyncEffect(() => async (signal) => {
        const newSettings = await baseApi.getSettings(signal);

        setSettings({
            ...newSettings,
            languages: newSettings.languages,
            uiLanguages: Object.entries(newSettings.uiLanguages)
                .reduce<Record<Language, LanguageName>>((acc, cur) => {
                    acc[cur[0].toLowerCase()] = cur[1];

                    return acc;
                }, {}),
        });
    }, [baseApi]);

    const contextValue = useMemo(() => ({ settings }), [settings]);

    if (!settings) {
        return (
            <Loader size={'lg'} center />
        );
    }

    return (
        <SettingsContext.Provider value={contextValue as SettingsContextType}>
            {children}
        </SettingsContext.Provider>
    );
}
