import { HttpError } from '~lib/http/http.error';
import { HttpRequest } from '~lib/http/http.request';
import { HttpStatus } from './http.enums';
import { HttpHeaders } from './http.headers';

export class HttpResponse {
    public error?: HttpError;

    public constructor(
        private readonly _response: Response, // it's raw response - keep private
        public readonly request: HttpRequest, // it's our wrapped request - can be public
    ) {
    }

    /**
     * HTTP response's headers
     */
    public get headers(): HttpHeaders {
        const headersObj: HttpHeaders = {};
        for (const [name, value] of this._response.headers.entries()) {
            headersObj[name] = value;
        }

        return headersObj;
    }

    public get ok(): boolean {
        return this._response.ok;
    }

    /**
     * HTTP response's status code
     */
    public get status(): HttpStatus {
        return this._response.status;
    }

    /**
     * HTTP response's status code
     */
    public get statusText(): string {
        return this._response.statusText;
    }

    /**
     * returns the raw body as a dictionary object
     * @returns
     */
    public async json(): Promise<any> {
        return await this._response.json();
    }

    public async jsonAs<T>(): Promise<T> {
        return await this.json() as T;
    }

    public async text(): Promise<string> {
        return await this._response.text();
    }

    /**
     * binds the response body to value of TValue. Note that there are no validations or anything, this is just to attach
     * some type to the response body instead of having an "any"... since we don't really know the actual response.
     * Only keys present on "value" will be assigned, all other response key/value pairs will be dropped.
     * @returns response mapped as TValue
     */
    public async bind<TValue extends object>(value: TValue): Promise<TValue> {
        // TODO it's a concept that can be used in some cases (e.g. Stakeholders, FlowElements). Consider allowing array of TValue as response which would be usual use case for this
        console.warn('Review this and make final implementation on first use');
        const jsonValue = await this._response.json();

        // re-map everything to camel case
        // do some cheating here to properly bind values... jsonValue will always be of type "any"
        // so we really have no idea what's on there
        for (const k of Object.keys(value)) {
            const pascalK = `${k[0].toUpperCase()}${k.substring(1)}`;

            if (jsonValue[k] != null) {
                (value as any)[k] = jsonValue[k];
            } else if (jsonValue[pascalK] != null) {
                (value as any)[k] = jsonValue[pascalK];
            }
        }

        return value as TValue;
    }
}