import { useContext } from 'react';
import { I18nContext, SetLanguageFunction } from '~contexts/i18n';
import { Language } from '~models';

export const useI18nContext = () => {
    return useContext(I18nContext);
};

export const useCurrentLanguage: () => Language = () => {
    const context = useContext(I18nContext);

    return context.language;
};

export const useLanguages = () => {
    const { languages, language, setLanguage } = useContext(I18nContext);

    return {
        languages,
        language,
        setLanguage,
    };
};

export const useSetLanguage: () => SetLanguageFunction = () => {
    const context = useContext(I18nContext);

    return context.setLanguage;
};
