import i18next from 'i18next';
import { useMemo } from 'react';
import { createHashRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';
import { BaseApi } from '~api/base.api';
import { LayoutContainer } from '~containers/layout';
import { ApiContextProvider } from '~contexts/api';
import { I18nContextProvider } from '~contexts/i18n';
import { SettingsContextProvider } from '~contexts/settings';
import ConfirmationView from '../../views/confirmation';
import FormView from '../../views/form';


export interface AppContainerProps {
}

const availableApis = [BaseApi];

const routerOptions = { basename: '/' };

const router = createHashRouter(createRoutesFromElements((
    <Route element={<LayoutContainer />}>
        <Route index element={<FormView />} />
        <Route path={'/confirmation'} element={<ConfirmationView />} />
        <Route path={'*'} element={<Navigate to={'/'} replace />} />
    </Route>
)), routerOptions);


export function AppContainer() {
    const baseUrl: string = useMemo(() => {
        const { origin, pathname } = window.location;

        return origin + pathname;
    }, []);

    return (
        <ApiContextProvider apiConstructors={availableApis} baseUrl={baseUrl}>
            <SettingsContextProvider>
                <I18nContextProvider i18next={i18next}>
                    <RouterProvider router={router}/>
                </I18nContextProvider>
            </SettingsContextProvider>
        </ApiContextProvider>
    );
}