export enum HttpMethod {
    Get = 'get',
    Post = 'post',
    Put = 'put',
    Patch = 'patch',
    Delete = 'delete',
    Head = 'head',
    Options = 'options',
}

export enum HttpStatus {
    Success = 200,
    Created = 201,
    Accepted = 202,
    NoContent = 204,

    // 3xx
    MovedPermanently = 301,
    Found = 302,

    // 4xx
    BadRequest = 400,
    Unauthorized = 401,
    Forbidden = 403,
    NotFound = 404,
}
