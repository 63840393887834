import { ReactNode, useMemo, useRef } from 'react';
import Http, { HttpRequest } from '~lib/http';
import { ApiContext } from './api.context';
import { ApiConstructor } from './api.models';


export interface ApiContextProviderProps {
    children: ReactNode;
    apiConstructors: ApiConstructor[];
    baseUrl?: string;
}

export function ApiContextProvider({ children, apiConstructors, baseUrl }: ApiContextProviderProps) {
    const errorCounter = useRef(0);

    const http = useMemo(() => {
        return new Http((request: HttpRequest) => {
            request.addErrorProcessor(() => {
                errorCounter.current++; // just for now
            });

            return request;
        }, baseUrl);
    }, [baseUrl]);

    const contextValue = useMemo(() => {
        const apis = [];

        for (const apiCtor of apiConstructors) {
            apis.push(new apiCtor(http));
        }

        return apis;
    }, [http, apiConstructors]);

    return (
        <ApiContext.Provider value={contextValue}>
            {children}
        </ApiContext.Provider>
    );
}
