import { changeLanguage, i18n } from 'i18next';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import Loader from '~components/loader';
import { SetLanguageFunction } from '~contexts/i18n/i18n.types';
import { useSettings } from '~contexts/settings';
import { useAsyncEffect } from '~hooks/effects';
import { configureI18next } from '~lib/i18next/i18next';
import { Language } from '~models';
import { I18nContext } from './i18n.context';

export interface I18nContextProviderProps {
    children: ReactNode;
    i18next: i18n;
}

const FALLBACK_LANGUAGE = 'fr';

export function I18nContextProvider({ children, i18next }: I18nContextProviderProps) {
    const [language, setLanguage] = useState<Language>();
    const { uiLanguages: languages } = useSettings();

    useAsyncEffect(() => async () => {
        if (!languages || i18next.isInitialized) {
            return;
        }
        await configureI18next(
            i18next, Object.keys(languages), FALLBACK_LANGUAGE,
        );
        const newLang = Object.keys(languages).find((lang: Language) => lang === i18next.language)!;

        setLanguage(newLang);
    }, [languages, i18next]);

    const setLanguageCallback = useCallback<SetLanguageFunction>(async (newLang: Language, useDefaultIfNotAvailable: boolean = false) => {
        let newLangProfile = Object.keys(languages).find((lang) => lang === newLang);

        if (!newLangProfile) {
            if (useDefaultIfNotAvailable) {
                newLangProfile = FALLBACK_LANGUAGE;
            } else {
                throw new Error('Language');
            }
        }
        await changeLanguage(newLang);
        setLanguage(newLangProfile);

        return newLangProfile;
    }, [languages]);

    const contextValue = useMemo(() => ({
        i18next,
        language: language!,
        languages,
        setLanguage: setLanguageCallback,
    }), [languages, i18next, language, setLanguageCallback]);

    if (!i18next.isInitialized || !language) {
        return <Loader size={'lg'} center />;
    }

    return (
        <I18nContext.Provider value={contextValue}>
            {children}
        </I18nContext.Provider>
    );
}
