/**
 * The `CustomError` has to be used as a base for al Errors instead of `Error` itself.
 * The problem is that `Error` breaks the prototype chain and any check for `instanceof`
 * will fail because it will always be an instance of `Error`.
 *
 * This `CustomError` fixes that by replacing this prototype with that of its own, restoring
 * the prototype chain.
 *
 * Here is more info: https://github.com/Microsoft/TypeScript-wiki/blob/main/Breaking-Changes.md#extending-built-ins-like-error-array-and-map-may-no-longer-work
 */
export class CustomError extends Error {
    /**
     * Indicates if this error has already been handled by someone, any consumer of this error is free to change this value
     * See: flow.ts as an example on how it's used
     */
    public handled: boolean;

    constructor(message?: string) {
        // 'Error' breaks prototype chain here
        super(message);

        this.handled = false;

        // restore prototype chain
        const actualProto = new.target.prototype;

        Object.setPrototypeOf(this, actualProto);
    }
}
