import { NdsBadge } from 'rcl';
import { NdsBadgeStatesEnum, NdsBadgeTypesEnum } from 'rcl/lib/_types/designsystem.enums';
import { PropsWithChildren, useMemo } from 'react';
import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import { LanguageDataLabel, SubscriptionStatus } from '~models';
import './form-details.scss';

export interface FormDetailsProps {
    currentUser: string;
    email: string;
    subscriptionName: string;
    planName: string;
    subscriptionState: SubscriptionStatus;
}

interface RowProps extends PropsWithChildren {
    label: LanguageDataLabel<'base'>;
}

const Row = ({ label, children }: RowProps) => {
    const { t } = useTranslation();

    return (
        <div className={'form-details-row'}>
            <Typography
                token={TypographyToken.DesktopDescriptionBoldMd}
                tagName={'div'}
                className={'form-details-cell'}
            >
                {t(label)}
            </Typography>
            <div className={'form-details-cell'}>
                {children}
            </div>
        </div>
    );
};
export const FormDetails = ({
    currentUser,
    email,
    subscriptionName,
    planName,
    subscriptionState,
}: FormDetailsProps) => {
    const { t } = useTranslation();
    const [badgeLabel, badgeState] = useMemo(() => {
        switch (subscriptionState) {
            case SubscriptionStatus.NotStarted:
                return [t('details-state-not-started'), NdsBadgeStatesEnum.NEUTRAL];
            case SubscriptionStatus.PendingFulfillmentStart:
                return [t('details-state-pending'), NdsBadgeStatesEnum.PENDING];
            case SubscriptionStatus.Subscribed:
                return [t('details-state-subscribed'), NdsBadgeStatesEnum.SUCCESS];
            case SubscriptionStatus.Suspended:
                return [t('details-state-suspended'), NdsBadgeStatesEnum.FAILURE];
            case SubscriptionStatus.Unsubscribed:
                return [t('details-state-unsubscribed'), NdsBadgeStatesEnum.WARNING];
            default:
                return ['-', NdsBadgeStatesEnum.NEUTRAL];
        }
    }, [subscriptionState, t]);

    return (
        <div className={'form-details'}>
            <Row label={'details-current-user'}>{currentUser}</Row>
            <Row label={'details-email'}>{email}</Row>
            <Row label={'details-subscription-name'}>{subscriptionName}</Row>
            <Row label={'details-plan-name'}>{planName}</Row>
            <Row label={'details-subscription-state'}>
                <NdsBadge
                    state={badgeState}
                    badgeType={NdsBadgeTypesEnum.STATUS}
                    icon={'fa-solid-circle'}
                    text={badgeLabel}
                />
            </Row>
        </div>
    );
};

export default FormDetails;