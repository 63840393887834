import classNames from 'classnames';
import { PropsWithClassNames } from '~models';
import defaultLogoLight from '~resources/logo-white.svg';
import './logo.scss';

export interface LogoProps extends PropsWithClassNames {
}

function Logo({ className }: LogoProps) {
    return (
        <div className={classNames('c-logo', className)}>
            <img src={defaultLogoLight} alt={'App logo'} />
        </div>
    );
}

export default Logo;