import { useContext } from 'react';
import { Type } from '~models/type';
import { ApiContext } from './api.context';

export const useApiContext = () => {
    return useContext(ApiContext)!;
};

export const useApi = <T, >(type: Type<T>) => {
    const apis = useContext(ApiContext)!;

    const found = apis.find(api => api instanceof type);

    if (found) {
        return found as T;
    }
    throw new Error(`Requested api '${type.prototype.name}' cannot be found. It's not registered or not exists`);
};
