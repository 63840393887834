/** HTTP headers key value type */
export type HttpHeaders = Record<string, string>;

export class HttpHeadersManager {

    public static readonly ContentType: string = 'Content-Type';

    public static readonly Authorization: string = 'Authorization';

    public static readonly CSRFToken: string = 'X-Csrf-Token';

    private static commonHeaders: HttpHeaders = {};
    private readonly headers: HttpHeaders;

    public constructor(initialHeaders: HttpHeaders = HttpHeadersManager.commonHeaders) {
        this.headers = initialHeaders;
    }

    public static setCommonHeader(name: string, value: string) {
        HttpHeadersManager.commonHeaders[HttpHeadersManager.canonicalize(name)] = value;
    }

    public static resetCommonHeader(name: string) {
        delete HttpHeadersManager.commonHeaders[HttpHeadersManager.canonicalize(name)];
    }

    public static getCommonHeaders(): HttpHeaders {
        return { ...HttpHeadersManager.commonHeaders };
    }

    /**
     * returns a "canonicalized" header name. capitalizing every first letter and keeping others lowercase.
     * some examples:
     *  AUTHORIZATION --> Authorization
     *  content-type  --> Content-Type
     *  x-TeNAnt-iD   --> X-Tenant-Id
     * @param headerName header name to be canonicalized
     * @returns canonicalized heaader name
     */
    public static canonicalize(headerName: string): string {
        return headerName.toLowerCase().replace(/-?\w+-?/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substring(1));
    }

    public setHeader(name: string, value: string) {
        this.headers[HttpHeadersManager.canonicalize(name)] = value;
    }

    public resetHeader(name: string) {
        delete this.headers[HttpHeadersManager.canonicalize(name)];
    }

    public getHeaders(): HttpHeaders {
        return {
            ...HttpHeadersManager.getCommonHeaders(),
            ...this.headers,
        };
    }
}