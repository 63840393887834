import Typography, { TypographyToken } from '~components/typography';
import { useTranslation } from '~contexts/i18n';
import './confirmation.view.scss';

export interface ConfirmationViewProps {
}

function ConfirmationView() {
    const { t } = useTranslation();

    return (
        <div className={'v-confirmation'}>
            <Typography token={TypographyToken.DesktopHeaderLg} tagName={'h1'} className={'mb--2'}>
                {t('main-title')}
            </Typography>
            <Typography
                token={TypographyToken.DesktopDescriptionMd}
                tagName={'p'}
                dangerouslySetInnerHTML={{ __html: t('confirmation') }}
            />
        </div>
    );
}

export default ConfirmationView;