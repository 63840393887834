import classNames from 'classnames';
import { Outlet } from 'react-router-dom';
import Content from '~components/content';
import Footer from '~components/footer';
import Header from '~components/header';
import Layout from '~components/layout';
import { PropsWithClassNames } from '~models';
import './layout.container.scss';

export interface LayoutContainerProps extends PropsWithClassNames {}

// This container provides application layout, so it's UI focused one. While some elements are common and lives in its
// own place all the time some are driven by props.
export const LayoutContainer = ({ className }: LayoutContainerProps) => {
    // order is important so no z-indexes are needed
    // so content is behind everything
    // footer is small and over content - no expandable elements would be in it
    // sidebar can go over content in case of some tooltips or sth like that
    // header - especially on mobile can be expanded to cover whole page, so it's on top
    return (
        <Layout className={classNames('ct-layout', className)}>
            <Layout.Content>
                <Content><Outlet /></Content>
            </Layout.Content>
            <Layout.Footer>
                <Footer />
            </Layout.Footer>
            <Layout.Header>
                <Header />
            </Layout.Header>
        </Layout>
    );
};
