import { NdsButton, NdsGroup, NdsMenu, NdsMenuItem } from 'rcl';
import { BkgModesEnum, NdsButtonTypesEnum, RelativePositionsEnum } from 'rcl/lib/_types';
import { SizesEnums, StatesEnum } from 'rcl/lib/_types/designsystem.enums';
import { useLanguages, useTranslation } from '~contexts/i18n';
import './headerMenu.scss';

export interface HeaderMenuProps {
}

function HeaderMenu(props: HeaderMenuProps) {
    const { t } = useTranslation();
    const {
        languages,
        language,
        setLanguage,
    } = useLanguages();

    return (
        <NdsMenu
            state={StatesEnum.DEFAULT}
            closeOnItemClick
            contentAlign={RelativePositionsEnum.BOTTOM_RIGHT}
            className={'c-header-menu'}
        >
            <NdsButton
                slot={'button'}
                label={languages[language]}
                bgMode={BkgModesEnum.DARK}
                buttonType={NdsButtonTypesEnum.NEUTRAL}
                size={SizesEnums.XSMALL}
            />
            <NdsGroup
                slot={'menu'}
                itemsSpacing={SizesEnums.MEDIUM}
                width={80}
                className={'c-header-menu__item'}
            >
                {Object.entries(languages).sort((a, b) => a[1] > b[1] ? 1 : -1).map(([lang, name]) => (
                    <NdsMenuItem
                        key={lang}
                        slot={'items'}
                        className={'c-header-menu__item'}
                        primaryText={name}
                        size={SizesEnums.MEDIUM}
                        onClick={() => {
                            setLanguage(lang);
                        }}
                    />
                ))}
            </NdsGroup>
        </NdsMenu>
    );
}

export default HeaderMenu;