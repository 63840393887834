import classNames from 'classnames';
import { createElement, HTMLProps, PropsWithChildren } from 'react';
import { TypographyToken } from '~components/typography';
import './typography.scss';

type ValidTags = keyof HTMLElementTagNameMap;

export interface TypographyProps<T extends ValidTags> extends PropsWithChildren, HTMLProps<HTMLElementTagNameMap[T]> {
    tagName?: T;
    token: TypographyToken;
    mediumScreenToken?: TypographyToken;
    largeScreenToken?: TypographyToken;
}

function Typography<T extends ValidTags>({
    tagName = 'span' as T,
    token,
    mediumScreenToken,
    largeScreenToken,
    className,
    children,
    ...props
}: TypographyProps<T>) {
    return createElement(
        tagName,
        {
            ...props,
            className: classNames(
                'c-typography',
                `c-typography--${token}`,
                {
                    [`c-typography--${mediumScreenToken}--medium`]: mediumScreenToken,
                    [`c-typography--${largeScreenToken}--large`]: largeScreenToken,
                },
                className,
            ),
        },
        children,
    );
}

export default Typography;